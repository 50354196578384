import React from "react"
import { graphql } from "gatsby"

import EventList from "../components/eventList"
import Snippet from "../components/snippet"

export default ({ data }) => (
	<EventList
		type="Interactive Events"
		meta={{
			title: "Interactive Events",
			description:
				"Check out the awesome interactive Java, JavaScript, and Ops event at Accento Digital on Juli 7th 2020!",
			keywords: `Java JavaScript DevOps events Karlsruhe`,
			path: "/interactives",
		}}
		lists={[
			{
				snippetId: "interactives-cloud",
				events: data.cloud.nodes,
				showByline: true,
			},
			{
				snippetId: "interactives-security",
				events: data.security.nodes,
				showByline: true,
			},
			{
				snippetId: "interactives-quality",
				events: data.quality.nodes,
				showByline: true,
			},
		]}
	>
		<Snippet id="interactives-intro" />
		<Snippet id="interactives-rooms" />
	</EventList>
)

export const query = graphql`
	query {
		cloud: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { frontmatter: { track: { eq: "cloud" } } }
		) {
			...AllEventNodes
		}
		security: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { frontmatter: { track: { eq: "security" } } }
		) {
			...AllEventNodes
		}
		quality: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { frontmatter: { track: { eq: "quality" } } }
		) {
			...AllEventNodes
		}
	}
`
